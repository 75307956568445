<template>
  <v-card
    outlined
    class="collezionamento-card product-card"
    :id="`collezionamento-card-${product.slug}`"
    ref="giftCard"
    :key="componentKey"
  >
    <div class="product">
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <span class="name">
              {{ product.name }}
            </span>
            <span class="stamps mb-1" v-if="showPoints">
              {{ product.productInfos["POINT_CARD_AMOUNT"] }}
              {{ $t("common.stamps") }} +
            </span>
            <ProductPrice
              v-if="product.price && product.price > 0.01"
              :product="product"
            />
            <span
              class="stamps mb-1"
              v-if="
                showPoints &&
                  product.warehousePromo &&
                  product.warehousePromo.selectable
              "
            >
              oppure {{ product.productInfos["POINT_CARD_AMOUNT"] }}
              {{ $t("common.stamps") }} +
            </span>
            <div class="product-price points-price">
              <span
                v-if="
                  product.warehousePromo && !product.warehousePromo.selectable
                "
                class="points-qty"
                v-html="product.warehousePromo.view.footer"
              ></span>
              <span
                v-else-if="
                  product.warehousePromo && product.warehousePromo.selectable
                "
                class="points-qty"
                v-html="product.warehousePromo.view.header"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div v-if="product.available > 0" class="actions">
        <div class="qty-wrap not-empty">
          <v-btn
            large
            aria-label="Diminuisci quantità"
            depressed
            class="minus rounded"
            :disabled="quantity <= 0"
            @click.stop.prevent="minus(true)"
          >
            <v-icon>$minus</v-icon>
          </v-btn>
          <div class="val-cnt">
            <span class="val">{{ quantity }}{{ unit }}</span>
          </div>
          <v-btn
            large
            aria-label="Aumenta quantità"
            class="plus rounded"
            depressed
            @click.stop.prevent="plus(true, { checkout: true })"
          >
            <v-icon>$plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-else
        class="actions justify-center align-center text-center text-caption font-weight-bold notAvailable"
      >
        {{ $t("product.notActive") }}
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.collezionamento-card {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 386px;
  }
  .product .description {
    height: 120px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product {
      padding: 16px 12px 10px 12px;
    }
  }
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-right: 15px;
      width: 100px;
      height: 100px;
    }
  }
  .name {
    color: var(--v-primary-base);
    font-weight: 600;
    margin-bottom: 0px !important;
  }
  .stamps {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 11px;
    }
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .product-price {
    color: $fidelity;
    margin-left: 0px !important;
    .cur-price {
      font-size: 1.5rem;
    }
    &.points-price {
      font-weight: 700;
      margin-top: 2px;
    }
  }

  .card-bottom {
    .actions {
    }
  }

  .description {
    line-height: 0.95;
  }
}
</style>
<script>
import ProductPrice from "./ProductPriceLeaflet.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

export default {
  name: "CollezionamentoCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  components: {
    ProductPrice
  },
  mixins: [productMixin],
  data() {
    return {
      componentKey: 0,
      key: 0
    };
  },

  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    showPoints() {
      return (
        this.product.productInfos?.PRODUCT_NATURE_ID === "8" &&
        this.product.productInfos?.POINT_CARD_AMOUNT?.length > 0
      );
    },
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId != 10002
      );
    },
    item() {
      this.key;
      let item = undefined;

      if (
        this.product.warehousePromo &&
        this.product.warehousePromo.selectable
      ) {
        item = this.getItem({
          product: this.product,
          use_wpid: -1
        });
        if (item) return item;

        item = this.getItem({
          product: this.product,
          use_wpid: this.product.warehousePromo.warehousePromoId
        });
      } else {
        item = this.getItem({
          product: this.product
        });
      }

      return item;
    }
  },
  methods: {},
  mounted() {}
};
</script>
